<template>
  <v-container
    fluid
    class="pa-0 snow-white"
  >
    <!----Desktop-->
    <div class="hidden-md-and-down snow-white">
      <hero-component
        background-url="/img/Contact-Background-Desktop.jpg"
      >
        {{ $t('contactus.title.part1') }} <br> {{ $t('contactus.title.part2') }}
      </hero-component>
      <div :class="xlOnly ? 'sec2-xl': 'sec2-lg'">
        <v-row class="pb-16">
          <v-col class="sec2-title pa-0">
            {{ $t('contactus.section2.h1') }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pt-16">
          <v-col
            cols="9"
            class="d-flex justify-center"
          >
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <v-icon
                x-large
                class="primary--text"
              >
                mdi-email-fast
              </v-icon>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <v-icon
                x-large
                class="primary--text"
              >
                mdi-phone
              </v-icon>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <v-icon
                x-large
                class="primary--text"
              >
                mdi-clock
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="9"
            class="d-flex justify-center"
          >
            <v-col
              cols="4"
              class="d-flex justify-center sec2-sub"
            >
              {{ $t('contactus.email') }}
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center sec2-sub"
            >
              {{ $t('contactus.phone') }}
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center sec2-sub"
            >
              {{ $t('contactus.bizhours') }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="9"
            class="d-flex justify-center"
          >
            <v-col
              cols="4"
              class="d-flex justify-center sec2-txt text-decoration-underline"
            >
            <a
              class="style-link"
              href="mailto:customerservice@blanket.com"
              >
              customerservice@blanket.com
             </a>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center sec2-txt"
            >
            <span class="padding-right">{{ $t("contactus.tollfree") }}:</span>
              <a
              class="style-link no-underline"
              href="tel:(833) 333-3145"
              >
              (833) 333-3145
              </a>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center sec2-txt"
            >
              {{ $t("contactus.time") }}
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-dsk">
        <div class="sec3-cnt">
          <v-row>
            <v-col class="sec3-title">
              {{ $t('contactus.section3.title') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="sec3-sub">
              {{ $t('contactus.section3.copy1') }}
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                large
                class="mint apply-btn-txt"
                to="/"
              >
                {{ $t('contactus.section3.copy2') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-img
          src="/img/dashboard/mobile-app-mockup.png"
          :class="xlOnly ? 'sec3-xl-phone' : 'sec3-dsk-phone'"
        ></v-img>
      </div>
    </div>
    <!----Tablet-->
    <div
      v-if="smOnly || mdOnly"
    >
      <hero-component
        background-url="/img/Contact-Background-Desktop.jpg"
      >
        {{ $t('contactus.tablet.title.part1') }} <br> {{ $t('contactus.tablet.title.part2') }} <br> {{ $t('contactus.tablet.title.part3') }} <br> {{ $t('contactus.tablet.title.part4') }}
      </hero-component>
      <div class="sec2-tablet">
        <v-row class="pb-16">
          <v-col class="sec2-title-tablet pa-0">
            {{ $t('contactus.tablet.section2.h1') }} <br> {{ $t('contactus.tablet.section2.h2') }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pt-16">
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <v-icon
                x-large
                class="primary--text"
              >
                mdi-email-fast
              </v-icon>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <v-icon
                x-large
                class="primary--text"
              >
                mdi-phone
              </v-icon>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <v-icon
                x-large
                class="primary--text"
              >
                mdi-clock
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center mt-0">
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-col
              cols="4"
              class="d-flex justify-center sec2-sub"
            >
              {{ $t('contactus.email') }}
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center sec2-sub"
            >
              {{ $t('contactus.phone') }}
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center sec2-sub"
            >
              {{ $t('contactus.bizhours') }}
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-flex justify-center pt-0"
          >
            <v-col
              cols="4"
              class="d-flex justify-center sec2-txt-tablet text-decoration-underline"
            >
            <a
              class="style-link"
              href="mailto:customerservice@blanket.com"
              >
              customerservice@blanket.com
             </a>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center sec2-txt-tablet"
            >
            <span class="padding-right">Toll free:</span>
            <a
            class="style-link no-underline"
            href="tel:(833) 333-3145"
            >
            (833) 333-3145
            </a>
            </v-col>
            <v-col
              cols="4"
              class="d-flex justify-center sec2-txt-tablet"
            >
            9am-6pm Eastern Time
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-tablet">
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            class="sec3-title d-flex justify-center"
          >
            {{ $t('contactus.section3.title') }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="sec3-sub d-flex justify-center">
            {{ $t('contactus.section3.copy1') }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pt-10">
          <v-col class="d-flex justify-center">
            <v-btn
              large
              class="mint apply-btn-txt"
              to="/"
            >
              {{ $t('contactus.section3.copy2') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        class="white-bar-tablet"
      ></div>
    </div>
    <!----Mobile-->
    <div v-if="xsOnly">
      <div class="sec1-mobile"></div>
      <div class="title-txt-mobile primary pa-8">
        {{ $t('contactus.mobile.title.part1') }}
      </div>
      <div class="sec2-mobile px-1 pb-16">
        <v-row>
          <v-col class="sec2-title-mobile">
            {{ $t('contactus.mobile.section2.h1') }}
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column">
          <v-col class="d-flex justify-center">
            <v-icon
              x-large
              class="primary--text"
            >
              mdi-email-fast
            </v-icon>
          </v-col>
          <v-col class="sec2-sub-mobile">
            {{ $t('contactus.email') }}
          </v-col>
          <v-col class="sec2-txt-mobile text-decoration-underline">
            <a
              class="style-link"
              href="mailto:customerservice@blanket.com"
              >
              customerservice@blanket.com
             </a>
          </v-col>
          <v-col class="d-flex justify-center">
            <v-icon
              x-large
              class="primary--text"
            >
              mdi-phone
            </v-icon>
          </v-col>
          <v-col class="sec2-sub-mobile">
            {{ $t('contactus.phone') }}
          </v-col>
          <v-col class="sec2-txt-mobile">
            <span class="padding-right">Toll free:</span>
            <a
            class="style-link no-underline"
            href="tel:(833) 333-3145"
            >
            (833) 333-3145
            </a>
          </v-col>
          <v-col class="d-flex justify-center">
            <v-icon
              x-large
              class="primary--text"
            >
              mdi-clock
            </v-icon>
          </v-col>
          <v-col class="sec2-sub-mobile">
            {{ $t('contactus.bizhours') }}
          </v-col>
          <v-col class="sec2-txt-mobile">
            9am-6pm Eastern Time
          </v-col>
        </v-row>
      </div>
      <div class="sec3-mobile px-2">
        <v-row>
          <v-col class="sec3-title-mobile pt-8">
            {{ $t('contactus.mobile.section3.title') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec3-sub-mobile">
            {{ $t('contactus.mobile.section3.copy1') }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pt-10">
          <v-col class="d-flex justify-center">
            <v-btn
              large
              class="mint apply-btn-txt mr-0"
              to="/"
            >
              {{ $t('contactus.mobile.section3.copy2') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        class="white-bar-tablet"
      ></div>
    </div>
  </v-container>
</template>

<script>
  import HeroComponent from '@/components/base/Hero'

  export default {
    components: {
      HeroComponent
    },
    computed: {
      xlOnly () {
        return this.$vuetify.breakpoint.xlOnly
      },
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smOnly () {
        return this.$vuetify.breakpoint.smOnly
      },
      mdOnly () {
        return this.$vuetify.breakpoint.mdOnly
      },
      lgOnly () {
        return this.$vuetify.breakpoint.lgOnly
      },
    }

  }
</script>

<style lang="scss" scoped>
  .sec2-xl {
    position: relative;
    height: 700px;
    padding-top: 79px;
    background-image: url('/img/white-pattern-pet.jpg');
    background-position: center;
    background-size: cover;
  }

  .sec2-lg {
    position: relative;
    height: 700px;
    padding-top: 79px;
    background-image: url('/img/white-pattern-pet.jpg');
    background-position: center;
    background-size: cover;
  }

  .sec2-title {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.42px;
color: #292F36;
  }

  .sec2-sub {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
  }

  .sec2-txt {
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
  }

  .sec3-dsk {
  position: relative;
  height: 356px;
  background: url('/img/dash-pattern.jpg');
  margin-bottom: 110px;
}
.sec3-dsk-phone {
  position: absolute;
left: 726px;
top: -78px;
}
.sec3-xl-phone {
  position: absolute;
left: 1100px;
top: -78px;
}
.sec3-cnt {
  position: absolute;
width: 516px;
height: 157px;
left: 794px;
top: 75px;
left: 214px;
}
.sec3-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */
letter-spacing: -0.424019px;
/* Color Palette/White (100%) */
color: #FFFFFF;
}
.sec3-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */
/* Color Palette/White (100%) */
color: #FFFFFF;
}

.apply-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 125%;
letter-spacing: 0.00892857em;
color: #292F36 !important;
}

.sec4 {
  background: #FFFFFF;
}

/*----------------TABLET-----------------*/
.sec2-tablet {
  position: relative;
    height: 645px;
    padding-top: 79px;
    background-image: url('/img/white-pattern-pet.jpg');
    background-position: center;
    background-size: cover;
}

.sec2-title-tablet {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.42px;
color: #292F36;
}

.sec2-txt-tablet {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 150%;
text-align: center;
color: #292F36;
}

.sec3-tablet {
  position: relative;
  height: 330px;
  background: url('/img/dash-pattern.jpg');
  padding-top: 52px;
}

.white-bar-tablet {
  height: 110px;
  background: #FFFFFF;
}
/* ------------------MOBILE----------------- */
.sec1-mobile {
  height: 385px;
  background-image: url('/img/Contact-Background-Mobile.jpg');
  background-position: center;
  background-size: cover;
}

.title-txt-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
/* or 40px */

text-align: center;
letter-spacing: -0.511589px;

/* white */

color: #FFFFFF;
}

.sec2-mobile {
    padding-top: 79px;
    background-image: url('/img/white-pattern-pet.jpg');
    background-position: center;
    background-size: cover;
}

.sec2-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
  }

  .sec2-sub-mobile {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 120%;
/* identical to box height, or 36px */

text-align: center;
letter-spacing: -0.424019px;

/* Dark Green */

color: #005F6C;
  }

  .sec2-txt-mobile {
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
  }

  .sec3-mobile {
    position: relative;
  height: 330px;
  background: url('/img/dash-pattern.jpg');
  }

  .sec3-title-mobile {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;
  }

  .sec3-sub-mobile {
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #FFFFFF;
  }
.style-link{
  color: #292f36;
  text-decoration:underline;
}
.padding-right{
  padding-right: 5px;
}
.no-underline {
  text-decoration: none!important;
}
</style>
